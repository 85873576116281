import { FC, useEffect, useRef, useState } from "react";
import {
    Heading,
    Text,
    Box,
    Picture,
    Flex,
    Button,
    Link,
    Container,
    Icon,
    ChevronLeftOutlineIcon,
    ChevronRightOutlineIcon,
} from "@smile2impress/components";

import { HorizontalScroll } from "components/HorizontalScroll";
import { sanitizeHtml } from "utils/sanitizeHtml";
import { debounce } from "utils/debounce";
import { useWindowDimensions } from "hooks/useWindowDimensions";

import {
    ScrollDirection,
    TmpContentSliderProps,
} from "./TmpContentSlider.types";
import { ImageTitle } from "./components/ImageTitle";
import { StackButtons } from "./components/StackButtons";

export const TmpContentSlider: FC<TmpContentSliderProps> = ({
    title,
    description,
    image,
    list,
    button,
    numberBadge,
    withCount,
    withCheckMarks,
    imageTitleTheme,
    onClickStackButton,
    linkTarget = "_self",
}) => {
    const ScrollContainer = useRef<HTMLDivElement>(null);
    const hasImage = Boolean(list?.[0]?.image);
    const hasIcon = withCount || withCheckMarks;
    const [showArrowButtons, setSAB] = useState<Boolean | undefined>(false);
    const [hideLeftButton, setHideLeftButton] = useState<Boolean>(true);
    const [hideRightButton, setHideRightButton] = useState<Boolean>(false);
    const { width } = useWindowDimensions();

    const baseScrollButtonsStyles = {
        variant: "ghost",
        minW: "auto",
        w: 10,
        h: 10,
        borderRadius: "50%",
        boxShadow: "0px 4px 22px hsla(0, 0%, 0%, 0.06)",
        background: "text.primaryInverse",
        position: "absolute" as const,
        zIndex: 10,
        display: ["none", "block"],
        transition: "opacity ease-in .05s",
        py: [3],
        px: [3],
    };

    const scrollButtonsStylesWithImage = {
        top: ["50px", "50px", "100px"],
    };

    const scrollButtonsStylesWithIcon = {
        top: "50%",
        marginTop: "-40px",
    };

    const isContentTooWide = (el: HTMLDivElement) => {
        const { scrollWidth, clientWidth } = el;
        return scrollWidth > clientWidth;
    };

    useEffect(() => {
        const { current } = ScrollContainer;
        if (current) {
            const debouncedScroll = debounce(() => {
                const { scrollLeft, scrollWidth, clientWidth } = current;
                const elWidth = scrollWidth / list.length;
                if (scrollLeft < elWidth / 2) {
                    setHideLeftButton(true);
                }
                if (scrollLeft >= elWidth / 2) {
                    setHideLeftButton(false);
                }
                if (scrollLeft + clientWidth < scrollWidth) {
                    setHideRightButton(false);
                }
                if (scrollLeft + clientWidth > scrollWidth - elWidth / 2) {
                    setHideRightButton(true);
                }
            }, 100);

            current?.addEventListener("scroll", debouncedScroll);
            setSAB(isContentTooWide(current) && (hasImage || hasIcon));
        }
    }, []);

    useEffect(() => {
        const { current } = ScrollContainer;
        if (current) {
            setSAB(isContentTooWide(current) && (hasImage || hasIcon));
        }
    }, [width]);

    const scrollSlider = (direction: ScrollDirection) => {
        const { current } = ScrollContainer;
        if (current) {
            const { scrollWidth } = current;
            current.scrollBy({
                left:
                    (scrollWidth / list.length) *
                    (direction === ScrollDirection.LEFT ? -1 : 1),
                top: 0,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            {image && (
                <Box mb={[5, 7]} borderTopRadius="xl" overflow="hidden">
                    <Picture {...image} fit="cover" />
                </Box>
            )}
            <Container variant="base">
                <Flex
                    direction={{ base: "column", desktop: "row" }}
                    mb={[5, 5, 8]}
                >
                    {numberBadge && (
                        <Box mr={[0, 3]} mb={[1]}>
                            <Heading
                                textStyle="title2"
                                color={["base.primary"]}
                                width={[5, 7]}
                                height={[5, 7]}
                                backgroundColor={["base.accent"]}
                                borderRadius="50%"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                            >
                                {numberBadge}
                            </Heading>
                        </Box>
                    )}

                    <Box pt={[0, 1]} maxW="900px">
                        {title && (
                            <Heading
                                mb={description ? [3, 3, 4] : 0}
                                textStyle="title1"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(title),
                                }}
                            />
                        )}
                        {description && (
                            <Text textStyle="body">{description}</Text>
                        )}
                    </Box>
                </Flex>
            </Container>
            <Box position="relative" overflow={["hidden", "visible"]}>
                {showArrowButtons && (
                    <>
                        <Button
                            {...baseScrollButtonsStyles}
                            {...(hasImage && scrollButtonsStylesWithImage)}
                            {...(hasIcon && scrollButtonsStylesWithIcon)}
                            onClick={() => scrollSlider(ScrollDirection.LEFT)}
                            marginLeft={[-5, -5]}
                            opacity={hideLeftButton ? 0 : 1}
                        >
                            <Icon as={ChevronLeftOutlineIcon} w={4} h={4} />
                        </Button>
                        <Button
                            {...baseScrollButtonsStyles}
                            {...(hasImage && scrollButtonsStylesWithImage)}
                            {...(hasIcon && scrollButtonsStylesWithIcon)}
                            onClick={() => scrollSlider(ScrollDirection.RIGHT)}
                            right={0}
                            marginRight={[-2, -5]}
                            opacity={hideRightButton ? 0 : 1}
                        >
                            <Icon as={ChevronRightOutlineIcon} w={4} h={4} />
                        </Button>
                    </>
                )}
                <HorizontalScroll scrollRef={ScrollContainer}>
                    <Flex width="fit-content" mb={button ? [3, 3, 6] : 0}>
                        {list.map((item, i) => (
                            <Box
                                {...(item?.link && {
                                    as: Link,
                                    href: item.link,
                                    target: linkTarget,
                                })}
                                mr={[2, 2, 5]}
                                _last={{
                                    mr: [3, 0, 0],
                                }}
                                _first={{
                                    ml: [3, 0, 0],
                                }}
                                display="flex"
                                flexDirection="column"
                                w={[231, 231, 376]}
                                key={i}
                            >
                                {item.image && (
                                    <Box
                                        mb={2}
                                        borderRadius="xl"
                                        overflow="hidden"
                                        h={[180, 180, 280]}
                                    >
                                        <Picture {...item.image} fit="cover" />
                                    </Box>
                                )}

                                <ImageTitle
                                    count={i + 1}
                                    theme={imageTitleTheme}
                                    withCount={withCount}
                                    withCheckMarks={withCheckMarks}
                                    title={item.title as string}
                                />
                                <Text
                                    mb={item.links ? [1, 2] : 0}
                                    textStyle="body"
                                >
                                    {item.text}
                                </Text>

                                {item.links && (
                                    <StackButtons
                                        theme={imageTitleTheme}
                                        links={item.links}
                                        onClick={onClickStackButton}
                                    />
                                )}
                            </Box>
                        ))}
                    </Flex>
                </HorizontalScroll>
            </Box>
            <Container variant="base">
                {button && (
                    <Flex justifyContent={["flex-start", "center"]}>
                        <Button
                            as={button.link ? Link : "button"}
                            {...(button.onClick && { onClick: button.onClick })}
                            {...(button.link && { href: button.link })}
                        >
                            {button.text}
                        </Button>
                    </Flex>
                )}
            </Container>
        </>
    );
};
