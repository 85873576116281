import { FC } from "react";
import { Flex, Box } from "@smile2impress/components";

import { ContainerDefault } from "../../styles";
import { PromoContainerProps } from "./PromoContainer.types";
import { LightPromoCard } from "../LightPromoCard";
import { DarkPromoCard } from "../DarkPromoCard";

export const PromoContainer: FC<PromoContainerProps> = ({
    quizPromo,
    retainersPromo,
    locationsPromo,
}) => {
    return (quizPromo?.button?.link || locationsPromo?.button?.link) &&
        retainersPromo?.button?.link ? (
        <ContainerDefault as={Flex} flexDirection={["column", "column", "row"]}>
            <Box width={["100%", "50%"]} mb={[10, 10, 0]} mr={[0, 0, 5]}>
                {quizPromo && <LightPromoCard {...quizPromo} />}
                {locationsPromo && <DarkPromoCard {...retainersPromo} />}
            </Box>
            <Box width={["100%", "50%"]}>
                {locationsPromo?.button?.link ? (
                    <LightPromoCard {...locationsPromo} />
                ) : (
                    <DarkPromoCard {...retainersPromo} />
                )}
            </Box>
        </ContainerDefault>
    ) : null;
};
