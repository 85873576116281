import { FC } from "react";
import { Text } from "@smile2impress/components";

import { TmpHalfimageBackground } from "components/templates/TmpHalfimageBackground";
import { CountryVersion } from "constants/enum";

import { AdvancedProps } from "./Advanced.types";

export const countriesWithAdvancedBlock = [
    CountryVersion.SPAIN,
    CountryVersion.PORTUGAL,
    CountryVersion.FRANCE,
    CountryVersion.ITALY,
    CountryVersion.GERMANY,
    CountryVersion.UK,
    CountryVersion.UKRAINE,
    CountryVersion.US,
];

export const Advanced: FC<AdvancedProps> = ({
    title,
    titleLabel,
    image,
    description,
    button,
}) => {
    return (
        <TmpHalfimageBackground
            title={title}
            titleLabel={titleLabel}
            image={image}
            button={button}
            rounded
            hasMobileButton
        >
            <Text mb={[3, 3, 5]}>{description}</Text>
        </TmpHalfimageBackground>
    );
};
