import React, { FC, useRef, useEffect } from "react";
import { Container } from "@smile2impress/components";

import { Questions } from "components/Questions";
import { useComputedFixedButtonOffset } from "hooks/useComputedFixedButtonOffset";
import { useFixedButtonContext } from "context/fixedButtonContext";
import { TmpHeaderBannerBackground } from "components/templates/TmpHeaderBannerBackground";
import { TmpContentSlider } from "components/templates/TmpContentSlider";
import { PriceBlock } from "components/PriceBlock";
import { useLocationContext } from "context/locationContext";
import { CountryVersion } from "constants/enum";

import { MainPageLayoutProps } from "./MainPageLayout.types";
import { OurDoctorsUs } from "./components/OurDoctorsUs";
import { ChecksList } from "./components/ChecksList";
import { BookingForm } from "./components/BookingForm";
import { ContainerDefault } from "./styles";
import { Advanced, countriesWithAdvancedBlock } from "./components/Advanced";
import { PromoContainer } from "./components/PromoContainer";

export const MainPageLayout: FC<MainPageLayoutProps> = ({
    content: {
        headerBanner,
        checkUp,
        steps,
        features,
        appointment,
        ourDoctorsUs,
        quizPromo,
        retainersPromo,
        advanced,
        locationsPromo,
    },
}) => {
    const appointmentRef = useRef<HTMLDivElement>(null);
    const { version } = useLocationContext();
    const isUS = version === CountryVersion.US;

    const { isFixedButtonActive } =
        useComputedFixedButtonOffset(appointmentRef);

    const { setFixedButton } = useFixedButtonContext();

    useEffect(() => {
        setFixedButton(isFixedButtonActive);
    }, [isFixedButtonActive, setFixedButton]);

    return (
        <Container pt={5}>
            <ContainerDefault>
                <TmpHeaderBannerBackground {...headerBanner} />
            </ContainerDefault>
            <ContainerDefault>
                <ChecksList {...checkUp} />
            </ContainerDefault>
            <PromoContainer
                quizPromo={quizPromo}
                retainersPromo={retainersPromo}
                locationsPromo={locationsPromo}
            />
            <ContainerDefault>
                <TmpContentSlider {...features} />
            </ContainerDefault>
            {isUS && ourDoctorsUs && (
                <ContainerDefault>
                    <OurDoctorsUs {...ourDoctorsUs} />
                </ContainerDefault>
            )}
            <ContainerDefault>
                <TmpContentSlider {...steps} />
            </ContainerDefault>
            <ContainerDefault>
                <PriceBlock />
            </ContainerDefault>
            {countriesWithAdvancedBlock.includes(version) && (
                <ContainerDefault>
                    <Advanced {...advanced} />
                </ContainerDefault>
            )}
            <ContainerDefault ref={appointmentRef}>
                <BookingForm {...appointment} />
            </ContainerDefault>
            <Container variant="base" mb={[10, 20]}>
                <Questions />
            </Container>
        </Container>
    );
};
