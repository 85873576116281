import { FC } from "react";
import { Heading, Icon, CheckmarkOutlineIcon } from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { ImageTitleProps, ImageTitleTheme } from "./ImageTitle.types";

export const ImageTitle: FC<ImageTitleProps> = ({
    title,
    theme,
    withCount,
    withCheckMarks,
    count,
}) => {
    return (
        <>
            {withCount && (
                <Heading
                    textStyle="title2"
                    w={[5, 5, 7]}
                    h={[5, 5, 7]}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color={
                        theme === ImageTitleTheme.BLUE
                            ? "action.primary2"
                            : "text.primary"
                    }
                    borderRadius="50%"
                    border="1px solid"
                    borderColor={
                        theme === ImageTitleTheme.BLUE
                            ? "action.primary2"
                            : "text.primary"
                    }
                    mb={[1, 1, 2]}
                >
                    {count}
                </Heading>
            )}
            {withCheckMarks && (
                <Heading
                    textStyle="title2"
                    w={[5, 5]}
                    h={[5, 5]}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="text.primary"
                    borderRadius="50%"
                    border="3px solid"
                    borderColor="text.primary"
                    mb={[1, 2]}
                >
                    <Icon
                        boxSize={5}
                        as={CheckmarkOutlineIcon}
                        color="base.accent"
                    />
                </Heading>
            )}
            {title && (
                <Heading
                    textStyle="title2"
                    color={
                        theme === ImageTitleTheme.BLUE
                            ? "action.primary2"
                            : "text.primary"
                    }
                    mb={[1, 2]}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(title),
                    }}
                />
            )}
        </>
    );
};
