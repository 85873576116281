import { FC, PropsWithChildren } from "react";
import {
    Flex,
    Heading,
    Button,
    Picture,
    Link,
    Text,
    Box,
} from "@smile2impress/components";

import { ButtonProps } from "types/button";
import { PictureProps } from "types/picture";
import { sanitizeHtml } from "utils/sanitizeHtml";

export interface TmpSmallPromoCardProps {
    title: string;
    image: PictureProps;

    button?: ButtonProps | null;
    onButtonClick?: () => void;
    rounded?: boolean;
    heightProps?: string[] | string;
    buttonProps?: any;
    text?: "string";
    ctaComment?: "string";
}

export const TmpSmallPromoCard: FC<PropsWithChildren<TmpSmallPromoCardProps>> =
    ({
        title,
        image,
        text,
        button,
        heightProps = "auto",
        buttonProps,
        rounded = false,
        ctaComment,
        onButtonClick,
    }) => (
        <Flex
            flexDir="column"
            overflow="hidden"
            w="100%"
            height={heightProps}
            borderRadius={rounded ? "xl" : 0}
        >
            <Box w="100%" minHeight={[240, 320, 460]} height={[240, 320, 460]}>
                <Picture {...image} fit="cover" />
            </Box>

            <Flex
                flexDir="column"
                justifyContent="space-between"
                w="100%"
                h="100%"
                px={[3, 3, 10]}
                py={[5, 5, 10]}
            >
                <Box mb={[3, 3, text ? 3 : 4]}>
                    <Heading
                        mb={["3", "3", 0]}
                        textStyle="title1"
                        color="inherit"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(title),
                        }}
                    />
                    {text && (
                        <Text mt={3} textStyle="body" color="inherit">
                            {text}
                        </Text>
                    )}
                </Box>

                {button && (
                    <Flex alignItems="center">
                        <Button
                            w="fit-content"
                            mr="2"
                            {...(onButtonClick
                                ? { onClick: onButtonClick }
                                : {
                                      as: Link,
                                      href: button?.link,
                                      target: button?.target,
                                  })}
                            {...buttonProps}
                        >
                            {button?.name}
                        </Button>
                        {ctaComment && (
                            <Text
                                color="text.accent"
                                textStyle="title3"
                                fontWeight="bold"
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: ctaComment,
                                    }}
                                />
                            </Text>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
