import { FC } from "react";
import {
    Container,
    Picture,
    Box,
    Heading,
    Text,
} from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { TmpHeaderBannerBackgroundProps } from "./TmpHeaderBannerBackground.types";

export const TmpHeaderBannerBackground: FC<TmpHeaderBannerBackgroundProps> = ({
    title,
    description,
    comment,
    image,
    children,
}) => {
    return (
        <Container
            as="section"
            display={["block", "block", "flex"]}
            flexDirection={"row-reverse"}
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
        >
            <Box
                overflow="hidden"
                mb={[5, 5, 0]}
                width={["100%", "100%", "50%"]}
                borderRadius={image.rounded ? "xl" : 0}
            >
                <Picture {...image} fit="cover" />
            </Box>
            <Box
                width={["100%", "100%", "50%"]}
                py={[0, 0, 10]}
                pr={[3, 0, 10]}
                pl={[3, 0, 0]}
            >
                <Heading
                    textStyle="display2"
                    mb={description ? 3 : 0}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(title),
                    }}
                />
                {description && (
                    <Heading textStyle="title3">{description}</Heading>
                )}

                {comment && (
                    <Text mt={3} textStyle="body">
                        {comment}
                    </Text>
                )}

                {children}
            </Box>
        </Container>
    );
};
