import { FC } from "react";
import { Flex, Picture, Button, Link } from "@smile2impress/components";

import { TmpHalfimageProps } from "./TmpHalfimage.types";

export const TmpHalfimage: FC<TmpHalfimageProps> = ({
    image,
    isReverse,
    children,
    button,
    styles,
}) => {
    return (
        <Flex
            __css={styles}
            display="flex"
            flexDir={["column", "column", isReverse ? "row-reverse" : "row"]}
        >
            <Flex
                w={["100%", "100%", "50%"]}
                borderRadius="xl"
                overflow="hidden"
                mb={[5, 5, 0]}
                position="relative"
                zIndex={1}
            >
                <Picture {...image} fit="cover" />
            </Flex>
            <Flex
                w={["100%", "100%", "50%"]}
                flexDir="column"
                justifyContent="center"
                px={[3, 3, 10]}
            >
                {children}
                {button && (
                    <Button
                        as={button.link ? Link : "button"}
                        width="fit-content"
                        borderRadius="md"
                        {...(button.onClick && { onClick: button.onClick })}
                        {...(button.link && { href: button.link })}
                    >
                        {button.text}
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};
