import { FC } from "react";
import { Box, Heading, Picture } from "@smile2impress/components";

import { TmpHalfimage } from "components/templates/TmpHalfimage";

import { OurDoctorsUsProps } from "./OurDoctorsUs.types";

export const OurDoctorsUs: FC<OurDoctorsUsProps> = ({
    image,
    button,
    title,
    badgeUrl,
}) => {
    return (
        <TmpHalfimage image={image} button={button}>
            <Box>
                <Box w={[8, 8, "94px"]} h={[8, 8, "94px"]} mb={[3, 3, 5]}>
                    <Picture defaultSrc={badgeUrl} />
                </Box>
                <Heading mb={[3, 3, 5]} textStyle="title1">
                    {title}
                </Heading>
            </Box>
        </TmpHalfimage>
    );
};
