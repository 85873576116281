import { FC } from "react";
import {
    Text,
    Box,
    Flex,
    Icon,
    CheckmarkThinOutlineIcon,
} from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { CheckUpListProps } from "./CheckUpList.types";

export const CheckUpList: FC<CheckUpListProps> = ({ list }) => {
    return (
        <Box listStyleType="none" p={0} m={0} as="ul">
            {list
                .filter((i) => Boolean(i))
                .map((item, index) => (
                    <Flex
                        as="li"
                        mb={2}
                        _last={{
                            mb: 0,
                        }}
                        key={index}
                    >
                        <Icon
                            color="text.accent"
                            minW={3}
                            minH={3}
                            as={CheckmarkThinOutlineIcon}
                        />
                        <Text
                            textStyle="body"
                            pl={2}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(item),
                            }}
                        />
                    </Flex>
                ))}
        </Box>
    );
};
