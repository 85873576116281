import { PictureProps } from "types/picture";

import { ImageTitleTheme } from "./components/ImageTitle";
import { ItemLink } from "./components/StackButtons";

export type TmpSlideProps = {
    image?: PictureProps;
    text?: string;
    id?: string;
    title?: string;
    link?: string;
    links?: ItemLink[];
};

export interface TmpContentSliderProps {
    list: TmpSlideProps[];

    title?: string;

    image?: PictureProps;
    description?: string;
    button?: {
        text?: string;
        link?: string;
        onClick?: () => void;
    };
    numberBadge?: number;
    withCount?: boolean;
    withCheckMarks?: boolean;
    imageTitleTheme?: ImageTitleTheme;

    linkTarget?: "_self" | "_blank" | "_parent" | "_top";
    onClickStackButton?: (id: string) => void;
}

export enum ScrollDirection {
    LEFT,
    RIGHT,
}
