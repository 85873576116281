import { FC, LegacyRef } from "react";
import classNames from "classnames/bind";

import { HorizontalScrollProps } from "./HorizontalScroll.types";
import styles from "./HorizontalScroll.module.scss";

const cx = classNames.bind(styles);

export const HorizontalScroll: FC<HorizontalScrollProps> = ({
    children,
    className,
    classNameScroll,
    scrollRef,
}) => (
    <div className={cx("root", className)}>
        <div
            ref={scrollRef as LegacyRef<HTMLDivElement>}
            className={cx("scroll", classNameScroll)}
        >
            {children}
        </div>
    </div>
);
