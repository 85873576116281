import { NextPageContext } from "next";
import loadNamespaces from "next-translate/loadNamespaces";
import useTranslation from "next-translate/useTranslation";

import content from "content";
import { CountryVersion, Language } from "constants/enum";
import { MainPageLayout } from "layouts/pages/MainPageLayout";
import { BaseLayout } from "layouts/BaseLayout";

const Main = ({
    language = Language.SPAIN,
    version = CountryVersion.SPAIN,
    isMainPage,
    withHeader,
    withFooter,
    contentFolder,
    statusCode,
}: any) => {
    const { t } = useTranslation();

    const pageContent = contentFolder
        ? content[contentFolder][version](t, { version, language })
        : {};

    return (
        <BaseLayout
            withHeader={withHeader}
            withFooter={withFooter}
            currentPage={contentFolder}
            layout={pageContent.layout}
            isErrorPage={Boolean(statusCode)}
            isMainPage={Boolean(isMainPage)}
            hideMenu={Boolean(pageContent.hideMenu)}
            hideRegionSelector={Boolean(pageContent.hideRegionSelector)}
            version={version}
            language={language}
        >
            <MainPageLayout content={pageContent} />
        </BaseLayout>
    );
};

Main.getInitialProps = async ({ query, pathname }: NextPageContext) => ({
    ...query,
    isNewLayout: true,
    isMainPage: true,
    ...(await loadNamespaces({
        locale: query.locale as string,
        pathname,
    })),
});

export default Main;
