import { FC } from "react";
import {
    Flex,
    Heading,
    Button,
    Picture,
    Link,
    Box,
} from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { TmpHalfimageBackgroundProps } from "./types";

export const TmpHalfimageBackground: FC<TmpHalfimageBackgroundProps> = ({
    title,
    titleLabel,
    image,
    children,
    button,
    heightProps = "auto",
    hasMobileButton = false,
    buttonProps,
    rounded = false,
    onButtonClick,
}) => (
    <Flex
        flexDir={["column", "column", "row"]}
        bgColor="base.secondary"
        overflow="hidden"
        height={heightProps}
        borderRadius={rounded ? "xl" : 0}
    >
        <Flex w={["100%", "100%", "50%"]}>
            <Picture {...image} fit="cover" />
        </Flex>

        <Flex
            flexDir="column"
            justifyContent="center"
            w={["100%", "100%", "50%"]}
            px={[3, 3, 10]}
            py={[5, 5, 12]}
        >
            <Box mb={["3", "3", "4"]}>
                <Heading
                    display={titleLabel ? "inline" : "block"}
                    textStyle="title1"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(title),
                    }}
                />
                {titleLabel && (
                    <Heading
                        color="text.primaryInverse"
                        backgroundColor="base.accent"
                        px={1}
                        borderRadius="base"
                        py={0.5}
                        textStyle="title4"
                        display="inline-block"
                        position="relative"
                        bottom={0.5}
                        ml={1}
                        boxShadow="elevation.medium"
                    >
                        {titleLabel}
                    </Heading>
                )}
            </Box>

            {children}

            {button && (
                <Button
                    w="fit-content"
                    display={
                        hasMobileButton
                            ? "inline-flex"
                            : ["none", "none", "inline-flex"]
                    }
                    {...(onButtonClick
                        ? { onClick: onButtonClick }
                        : {
                              as: Link,
                              href: button?.link,
                              target: button?.target,
                          })}
                    {...buttonProps}
                >
                    {button?.name}
                </Button>
            )}
        </Flex>
    </Flex>
);
