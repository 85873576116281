import { useContext, createContext } from "react";

const FixedButtonContext = createContext({
    isFixedButton: true,
    setFixedButton: (_value: boolean) => {},
});

export const FixedButtonContextProvider = FixedButtonContext.Provider;

export const useFixedButtonContext = () => useContext(FixedButtonContext);
