import { RefObject, useEffect, useState } from "react";
import { pathOr } from "ramda";

import { WINDOW_SIZE } from "constants/enum";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { throttle } from "utils/throttle";

interface ReturnComputedFixedButtonOffsetProps {
    isFixedButtonActive: boolean;
    scrollToAppointment: (isRefOriented?: boolean) => void;
}

const RATIO_FOR_SCROLL_FORM = 30;

export const useComputedFixedButtonOffset = (
    ref: RefObject<HTMLDivElement>
): ReturnComputedFixedButtonOffsetProps => {
    const hasWindow = typeof window !== "undefined";
    const { height, width } = useWindowDimensions();

    const [isFixedButtonActive, setIsFixedButtonActive] = useState(false);
    const [formOffsetTop, setFormOffsetTop] = useState(0);
    const [formOffsetHeight, setFormOffsetHeight] = useState(0);

    const scrollToAppointment = (isRefOriented?: boolean) => {
        const offsetTop = pathOr(0, ["current", "offsetTop"], ref);

        window.scrollTo({
            top: isRefOriented
                ? offsetTop - RATIO_FOR_SCROLL_FORM
                : formOffsetTop - RATIO_FOR_SCROLL_FORM,
            behavior: "smooth",
        });
    };

    const callback = throttle(() => {
        setIsFixedButtonActive(
            pageYOffset < formOffsetTop - height ||
                pageYOffset > formOffsetTop + formOffsetHeight
        );
    }, 10);

    useEffect(() => {
        if (ref?.current) {
            callback();

            setFormOffsetTop(ref?.current?.offsetTop || 0);
            setFormOffsetHeight(ref?.current?.offsetHeight || 0);
        }
    }, [ref?.current]);

    useEffect(() => {
        if (hasWindow && width > 0 && width < WINDOW_SIZE.TABLET) {
            window.addEventListener("scroll", callback);

            return () => window.removeEventListener("scroll", callback);
        }
    }, [width, formOffsetTop, formOffsetHeight]);

    return {
        isFixedButtonActive,
        scrollToAppointment,
    };
};
