import { chakra } from "@chakra-ui/react";
import { Container } from "@smile2impress/components";

export const ContainerDefault = chakra(Container, {
    baseStyle: {
        maxW: ["604px", "604px", "none"],
        mx: ["auto", "auto", 0],
        mb: ["140px", "160px"],
    },
});
