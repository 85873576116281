import { FC } from "react";
import { Flex } from "@smile2impress/components";

import {
    TmpSmallPromoCard,
    TmpSmallPromoCardProps,
} from "components/templates/TmpSmallPromoCard";

export const LightPromoCard: FC<TmpSmallPromoCardProps> = (props) => {
    return (
        <Flex bgColor="base.secondary" borderRadius="xl" height="100%">
            <TmpSmallPromoCard {...props} rounded />
        </Flex>
    );
};
