import {
    Button,
    Box,
    UnionFilledIcon,
    UnionOutlineIcon,
    Icon,
    useMediaQuery,
} from "@smile2impress/components";
import { FC } from "react";

import { getAnalyticsId } from "utils/AnalyticsId";

import { ImageTitleTheme } from "../ImageTitle";
import { StackButtonsProps } from "./StackButtons.types";

export const StackButtons: FC<StackButtonsProps> = ({
    links,
    onClick,
    theme,
}) => {
    const [isDesktop] = useMediaQuery("(min-width: 1024px)");
    const isBlueTheme = theme === ImageTitleTheme.BLUE;

    return (
        <Box>
            {links.map((link) => (
                <Box mb={1} _last={{ mb: 0 }} key={link.text}>
                    <Button
                        {...(link?.to && {
                            as: "a",
                            href: link.to,
                        })}
                        {...(onClick && {
                            onClick: () => onClick(link.id || link.text),
                        })}
                        id={getAnalyticsId({
                            zone: "services",
                            label: link.id,
                        })}
                        variant={isBlueTheme ? "primary2" : "primary"}
                        w={isBlueTheme ? "auto" : "100%"}
                    >
                        {link.text}
                        {isBlueTheme && (
                            <Icon
                                ml={[0.5, 0.5, 1]}
                                as={
                                    isDesktop
                                        ? UnionFilledIcon
                                        : UnionOutlineIcon
                                }
                            />
                        )}
                    </Button>
                </Box>
            ))}
        </Box>
    );
};
