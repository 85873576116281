/* eslint-disable @typescript-eslint/no-this-alias */
export const debounce = (fn: () => void, wait: number, immediate = false) => {
    let timeout: ReturnType<typeof setTimeout> | null;
    return function (...args: any[]) {
        //@ts-ignore
        const context: any = this;
        timeout && clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            //@ts-ignore
            if (!immediate) fn.apply(context, args);
        }, wait);
        //@ts-ignore
        if (immediate && !timeout) fn.apply(context, args);
    };
};
