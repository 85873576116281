import { FC } from "react";
import { Heading } from "@smile2impress/components";

import { BookAppointmentFullForm } from "components/BookAppointmentFullForm";
import { TmpHalfimage } from "components/templates/TmpHalfimage";
import { getAnalyticsId } from "utils/AnalyticsId";

import { BookingFormProps } from "./BookingForm.types";

export const BookingForm: FC<BookingFormProps> = ({ image, title }) => (
    <TmpHalfimage image={image}>
        <Heading mb={5} textStyle="title1">
            {title}
        </Heading>
        <BookAppointmentFullForm
            analyticId={getAnalyticsId({
                zone: "main-page",
            })}
        />
    </TmpHalfimage>
);
