import { FC } from "react";
import { Box } from "@smile2impress/components";

import { CheckUpList } from "components/CheckUpList";
import { TmpHalfimageBackground } from "components/templates/TmpHalfimageBackground";

import { ChecksListProps } from "./ChecksList.types";

export const ChecksList: FC<ChecksListProps> = ({ list, ...props }) => {
    return (
        <TmpHalfimageBackground {...props}>
            <Box mb={[0, 0, 5]}>
                <CheckUpList list={list} />
            </Box>
        </TmpHalfimageBackground>
    );
};
