import { FC } from "react";
import { Flex } from "@smile2impress/components";

import {
    TmpSmallPromoCard,
    TmpSmallPromoCardProps,
} from "components/templates/TmpSmallPromoCard";

export const DarkPromoCard: FC<TmpSmallPromoCardProps> = (props) => {
    return (
        <Flex
            color="text.primaryInverse"
            bg="linear-gradient(310.3deg, rgba(5, 79, 227, 0.2) -47.74%, rgba(235, 94, 91, 0.2) 7.44%, rgba(0, 0, 0, 0.2) 92.72%), #1D2832;"
            borderRadius="xl"
            height="100%"
        >
            <TmpSmallPromoCard {...props} rounded />
        </Flex>
    );
};
