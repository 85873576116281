export enum ImageTitleTheme {
    DEFAULT = "default",
    BLUE = "BLUE",
}

export type ImageTitleProps = {
    title: string;

    theme?: ImageTitleTheme;
    withCount?: boolean;
    withCheckMarks?: boolean;
    count?: number;
};
